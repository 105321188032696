import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";

export default function Services() {
  return (
    <Wrapper id="services">
      <div className="darkBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <img src="https://djokerbet.com/hotogel/footer/ft_banner.png" alt="Sertifikasi Ampera4D" style={{width: "100%", height: "4rem"}} />
        </div>
      </div>
      <div className="transBg darkColor" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">RTP AMPERA4D</h1>
            <h2 className="font30 extraBold">Mengapa RTP Penting?</h2>
            <p className="font20">
              RTP adalah faktor yang sangat penting dalam menentukan seberapa adil suatu permainan kasino. 
              Semakin tinggi RTP, semakin besar peluang pemain untuk mendapatkan kembali sebagian besar dari uang yang mereka pertaruhkan. 
              Dengan kata lain, RTP membantu pemain menilai apakah suatu permainan kasino memiliki keunggulan rumah yang tinggi atau rendah.
            </p>
            <h3 className="font25">Bagaimana RTP Mempengaruhi Permainan Kasino?</h3>
            <ol>
              <li>
                <strong>Slot Online:</strong><br/>
                Slot online adalah contoh permainan yang sering dikaitkan dengan RTP. Slot dengan RTP tinggi cenderung memberikan kemenangan lebih sering, sementara slot dengan RTP rendah mungkin mengharuskan pemain untuk bersabar dalam mengejar kemenangan besar.
              </li>
              <li>
                <strong>Permainan Meja:</strong><br/> 
                RTP juga memengaruhi permainan meja seperti blackjack, roulette, dan poker. Meskipun dalam permainan ini, pengaruh RTP mungkin tidak sebesar pada slot, pemain masih ingin memilih permainan dengan RTP yang lebih baik untuk meningkatkan peluang menang.
              </li>
            </ol>
            <h3 className="font25">Tips Mengenai RTP</h3>
            <ol>
              <li>
                <strong>Pilih Game dengan RTP Tinggi:</strong><br/>
                Saat memilih permainan kasino online, carilah game dengan RTP tinggi. Ini meningkatkan peluang Anda untuk menghasilkan keuntungan dalam jangka panjang.
              </li>
              <li>
                <strong>Mengelola Bankroll:</strong><br/>
                Penting untuk selalu memiliki manajemen bankroll yang baik. Jangan pernah bertaruh lebih dari yang Anda mampu kehilangan, terlepas dari RTP.
              </li>
              <li>
                <strong>Pahami Permainan:</strong><br/>
                Sebelum Anda mulai bertaruh, pahami peraturan dan strategi permainan yang Anda mainkan. Pengetahuan akan permainan dapat membantu Anda memaksimalkan penggunaan RTP.
              </li>
              <li>
                <strong>Jangan Jadikan RTP sebagai Satu-satunya Pertimbangan:</strong><br/>
                Meskipun RTP penting, jangan jadikan faktor ini sebagai satu-satunya pertimbangan dalam memilih permainan. Juga pertimbangkan aspek-aspek seperti hiburan, tema, dan gaya permainan.
              </li>
            </ol>
          </HeaderInfo>
          <ButtonsRow className="flexCenter" style={{ margin: "30px 0"}}>
            <div style={{ width: "100%" }}>
              <a href="https://rebrand.ly/RTP-ampera4d">
                <FullButton title="RTP SLOT AMPERA4D" border />
              </a>
            </div>
          </ButtonsRow>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;