import React from "react";
import styled from "styled-components";

export default function Blog() {
  return (
    <Wrapper id="blog">
      <div className="greyBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold" style={{marginBottom: "2rem"}}>TENTANG AMPERA4D</h1>
            <p className="font20 textJustify">
              Ampera4D Merupakan Agen Judi Online Terbaik Yang Memberikan Pelayanan Terbaik Dengan Layanan 24 Jam Non Stop Terbaik di Indonesia.
              Ampera4D Memiliki Pelayanan Deposit dan Withdraw Terbaik dan Terlengkap Dari Semua Bank yang Ada dan E-wallet Terbaik di Indonesia.
            </p>

            <div className="row textCenter" style={{display: "flex", marginTop: "3rem"}}>
            <p className="font20 textJustify container">Klik Untuk Hubungi Sosmed Kami : </p>
              <table style={{width: "100%", margin: "auto", background: "transparent", borderRadius: "5px", marginTop: "2rem"}}>
                <tbody>
                  <tr>
                    <td >
                      <a href="https://rebrand.ly/ampera-wa" target="_blank" rel="noreferrer">
                        <img className="iconimg" src="https://djokerbet.com/hotogel/sosmed/whatsapp.png" alt="Whatsapp Ampera4D" />
                      </a>
                    </td>
                    <td >
                      <a href="https://www.facebook.com/people/Ampera4d/61551768536732/" target="_blank" rel="noreferrer">
                        <img className="iconimg" src="https://djokerbet.com/hotogel/sosmed/facebook.png" alt="Facebook Ampera4D" />
                      </a>
                    </td>
                    <td >
                      <a href="https://twitter.com/ampera4d/" target="_blank" rel="noreferrer">
                        <img className="iconimg" src="https://djokerbet.com/hotogel/sosmed/twitter.png" alt="Twitter Ampera4D" />
                      </a>
                    </td>
                    <td >
                      <a href="https://www.instagram.com/ampera.4d/" target="_blank" rel="noreferrer">
                        <img className="iconimg" src="https://djokerbet.com/hotogel/sosmed/instagram.png" alt="Instagram Ampera4D" />
                      </a>
                    </td>
                    <td >
                      <a href="https://t.me/AMPERA4D" target="_blank" rel="noreferrer">
                        <img className="iconimg" src="https://djokerbet.com/hotogel/sosmed/telegram.png" alt="Telegram Ampera4D" />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </HeaderInfo>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding-top: 20%;
  padding-bottom: 20%;
  @media (max-width: 860px) {
    text-align: center;
    padding-top: 8rem;
    padding-bottom: 9rem;
  }
`;