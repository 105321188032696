import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
// Assets
import Olympus from "../../assets/img/olympus.png";
import Starlight from "../../assets/img/starlight.png";
import Bonanza from "../../assets/img/bonanza.png";
import Wildwest from "../../assets/img/wildwest.png";
import Lions from "../../assets/img/5lions.png";
import Gatotkaca from "../../assets/img/gatotkaca.png";

export default function Projects() {
  return (
    <Wrapper id="projects">
      <div className="whiteColor dtransBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Games Favorit dan Populer Ampera4D</h1>
            <p className="font120">
              Permainan Slot Populer Favorit Untuk Para Pemain Slot Khususnya Slot Pragmatic
              <br />
              Beragam Aneka Permainan Slot Tersedia Dari Pragmatic, PG Soft, Slot88, Habanero, dan Lainnya
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <a href="https://ampera4d.cc">
                <ProjectBox
                  img={Olympus}
                  title="Gates of Olympus"
                  text="Permainan Slot Kakek Zeus Dari Pragmatic Memberikan Banyak Petir Perkalian Scatter Maxwin"
                />
              </a>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <a href="https://ampera4d.cc">
                <ProjectBox
                  img={Starlight}
                  title="Starlight Princess"
                  text="Permainan Slot Mimi Peri Dari Pragmatic Memberikan Banyak Pecahan Perkalian Scatter"
                />
              </a>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <a href="https://ampera4d.cc">
                <ProjectBox
                  img={Bonanza}
                  title="Sweet Bonanza"
                  text="Permainan Slot Permen dan Buah Dari Pragmatic Memberikan Banyak Perkalian Scatter Bonanza"
                />
              </a>
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <a href="https://ampera4d.cc">
                <ProjectBox
                  img={Wildwest}
                  title="Wild West Gold"
                  text="Permainan Slot Wild West Dari Pragmatic Memberikan Banyak Perkalian Scatter Gold Mendapatkan Jackpot Maxwin"
                />
              </a>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <a href="https://ampera4d.cc">
                <ProjectBox
                  img={Lions}
                  title="5 Lions Megaways"
                  text="Permainan Slot Gerbang Patung Singa Dari Pragmatic Memberikan Banyak Perkalian Scatter Maxwin Jackpot"
                />
              </a>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <a href="https://ampera4d.cc">
                <ProjectBox
                  img={Gatotkaca}
                  title="Gates of Gatotkaca"
                  text="Permainan Slot Bernuansa Nusantara Gatot Kaca yang Memberikan Sensasi Menarik dan Jackpot Maxwin dari Pragmatic"
                />
              </a>
            </div>
          </div>
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              <a href="https://ampera4d.cc">
                <FullButton title="MAINKAN SEKARANG" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
  padding-top: 10%
`;
