import React from "react";
import styled from "styled-components";

export default function FullButton({ title, action, border }) {
  return (
    <Wrapper
      className="animate pointer radius8 font18"
      onClick={action ? () => action() : null}
      border={border}
    >
      {title}
    </Wrapper>
  );
}

const Wrapper = styled.button`
  border: 1px solid ${(props) => (props.border ? "#ffd620" : "#ffd620")};
  background-color: ${(props) => (props.border ? "#0B093B" : "#ffd620")};
  width: 100%;
  height: 4rem;
  outline: none;
  color: ${(props) => (props.border ? "#ffd620" : "#0b093b")};
  :hover {
    background-color: ${(props) => (props.border ? "#0B093B" : "#ffd620")};
    border: 1px solid #ffd620;
    color: ${(props) => (props.border ? "#ffd620" : "#0b093b")};
  }
`;

